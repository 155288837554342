export default {
    default1: {
        title: 'default1',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '2_1_3',
                items: ['phone', 'email', 'logo', 'account', 'cart', null],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    default2: {
        title: 'default2',
        template: [
            {
                type: 'Top',
                template: '1_4',
                items: ['infoMenu', 'phone', 'whatsappIconTitle', 'telegram', 'email'],
            },
            {
                type: 'Main',
                template: '1_space_3',
                items: ['logo', 'search', 'account', 'cart', null],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    default3: {
        title: 'default3',
        template: [
            {
                type: 'Main',
                template: '1_space_2',
                items: ['logo', 'search', 'phone', 'email'],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'account', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    default4: {
        title: 'default4',
        template: [
            {
                type: 'Main',
                template: '1_4_2',
                items: ['logo', 'phone', 'email', null, null, 'account', 'cart'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    default5: {
        title: 'default5',
        template: [
            {
                type: 'Top',
                template: '1_2',
                items: ['infoMenu', 'phone', 'email'],
            },
            {
                type: 'Main',
                template: '1_1_2',
                items: ['search', 'logo', 'account', 'cart'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    default6: {
        title: 'default6',
        template: [
            {
                type: 'Main',
                template: '1_space_3',
                items: ['logo', 'mainMenu', 'phone', 'account', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    default7: {
        title: 'default7',
        template: [
            {
                type: 'Main',
                template: '1_1_3',
                items: ['mainMenu', 'logo', 'phone', 'account', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0001': {
        title: 'dh-111-i-0001',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '2_1_2',
                items: ['phones', 'whatsapps', 'logo', 'workTime', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0002': {
        title: 'dh-111-i-0002',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '2_1_2',
                items: ['phones', 'whatsapps', 'logo', 'workTime', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0003': {
        title: 'dh-111-i-0003',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '2_1_2',
                items: ['phones', 'whatsapps', 'logo', 'workTime', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'account', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0004': {
        title: 'dh-111-i-0004',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '2_1_3',
                items: ['phone', 'whatsapp', 'logo', 'cart', 'buttonPopup', null],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0005': {
        title: 'dh-111-i-0005',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_1_3',
                items: ['search', 'logo', 'phone', 'whatsappIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0006': {
        title: 'dh-111-i-0006',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_1_3',
                items: ['search', 'logo', 'phone', 'whatsappIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0007': {
        title: 'dh-111-i-0007',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_1_3',
                items: ['search', 'logo', 'phone', 'whatsappIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'account', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0008': {
        title: 'dh-111-i-0008',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_1_3',
                items: ['search', 'logo', 'phone', 'whatsappIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'cart', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0009': {
        title: 'dh-111-i-0009',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_1_3',
                items: ['search', 'logo', 'phone', 'whatsappIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: '1_3',
                items: ['mainMenu', 'account', 'cart', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0010': {
        title: 'dh-111-i-0010',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_1_2',
                items: [
                    'search',
                    'logo',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0011': {
        title: 'dh-111-i-0011',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_1_2',
                items: [
                    'search',
                    'logo',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0012': {
        title: 'dh-111-i-0012',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_1_2',
                items: [
                    'search',
                    'logo',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0013': {
        title: 'dh-111-i-0013',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_1_2',
                items: [
                    'search',
                    'logo',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'account', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0014': {
        title: 'dh-111-i-0014',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_1_2',
                items: [
                    'search',
                    'logo',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'cart', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0015': {
        title: 'dh-111-i-0015',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_1_2',
                items: [
                    'search',
                    'logo',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_3',
                items: ['mainMenu', 'account', 'cart', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0001': {
        title: 'dh-011-i-0001',
        template: [
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'infoMenu',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0002': {
        title: 'dh-011-i-0002',
        template: [
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'infoMenu',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0003': {
        title: 'dh-011-i-0003',
        template: [
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'infoMenu',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0004': {
        title: 'dh-011-i-0004',
        template: [
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'infoMenu',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0005': {
        title: 'dh-011-i-0005',
        template: [
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'infoMenu',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'account', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0006': {
        title: 'dh-011-i-0006',
        template: [
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'infoMenu',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'cart', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0007': {
        title: 'dh-011-i-0007',
        template: [
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'infoMenu',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_3',
                items: ['mainMenu', 'account', 'cart', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0008': {
        title: 'dh-011-i-0008',
        template: [
            {
                type: 'Main',
                template: '1_space_3',
                items: [
                    'logo',
                    'infoMenu',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                    'buttonPopup',
                ],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0009': {
        title: 'dh-011-i-0009',
        template: [
            {
                type: 'Main',
                template: '1_space_3',
                items: [
                    'logo',
                    'infoMenu',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                    'buttonPopup',
                ],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0010': {
        title: 'dh-011-i-0010',
        template: [
            {
                type: 'Main',
                template: '1_space_3',
                items: [
                    'logo',
                    'infoMenu',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                    'buttonPopup',
                ],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0011': {
        title: 'dh-011-i-0011',
        template: [
            {
                type: 'Main',
                template: '1_space_3',
                items: [
                    'logo',
                    'infoMenu',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                    'buttonPopup',
                ],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'account', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0012': {
        title: 'dh-011-i-0012',
        template: [
            {
                type: 'Main',
                template: '1_space_4',
                items: ['logo', 'infoMenu', 'phone', 'whatsappIconOnly', 'telegramIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0013': {
        title: 'dh-011-i-0013',
        template: [
            {
                type: 'Main',
                template: '1_space_4',
                items: ['logo', 'infoMenu', 'phone', 'whatsappIconOnly', 'telegramIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0014': {
        title: 'dh-011-i-0014',
        template: [
            {
                type: 'Main',
                template: '1_space_4',
                items: ['logo', 'infoMenu', 'phone', 'whatsappIconOnly', 'telegramIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0015': {
        title: 'dh-011-i-0015',
        template: [
            {
                type: 'Main',
                template: '1_space_4',
                items: ['logo', 'infoMenu', 'phone', 'whatsappIconOnly', 'telegramIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0016': {
        title: 'dh-011-i-0016',
        template: [
            {
                type: 'Main',
                template: '1_space_4',
                items: ['logo', 'infoMenu', 'phone', 'whatsappIconOnly', 'telegramIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'account', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0017': {
        title: 'dh-011-i-0017',
        template: [
            {
                type: 'Main',
                template: '1_space_4',
                items: ['logo', 'infoMenu', 'phone', 'whatsappIconOnly', 'telegramIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'cart', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0018': {
        title: 'dh-011-i-0018',
        template: [
            {
                type: 'Main',
                template: '1_space_4',
                items: ['logo', 'infoMenu', 'phone', 'whatsappIconOnly', 'telegramIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: '1_3',
                items: ['mainMenu', 'account', 'cart', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0019': {
        title: 'dh-011-i-0019',
        template: [
            {
                type: 'Main',
                template: '1_space_2',
                items: ['logo', 'infoMenu', 'buttonPhone', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0020': {
        title: 'dh-011-i-0020',
        template: [
            {
                type: 'Main',
                template: '1_space_2',
                items: ['logo', 'infoMenu', 'buttonPhone', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0021': {
        title: 'dh-011-i-0021',
        template: [
            {
                type: 'Main',
                template: '1_space_2',
                items: ['logo', 'infoMenu', 'buttonPhone', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0022': {
        title: 'dh-011-i-0022',
        template: [
            {
                type: 'Main',
                template: '1_space_2',
                items: ['logo', 'infoMenu', 'buttonPhone', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'account', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0023': {
        title: 'dh-011-i-0023',
        template: [
            {
                type: 'Main',
                template: '2_space_3',
                items: ['logo', 'buttonPopup', 'infoMenu', 'phone', 'account', 'cart'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0024': {
        title: 'dh-011-i-0024',
        template: [
            {
                type: 'Main',
                template: '2_space_3',
                items: ['logo', 'buttonPopup', 'infoMenu', 'phone', 'account', 'cart'],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-011-i-0025': {
        title: 'dh-011-i-0025',
        template: [
            {
                type: 'Main',
                template: '2_space_3',
                items: ['logo', 'buttonPopup', 'infoMenu', 'phone', 'account', 'cart'],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0016': {
        title: 'dh-111-i-0016',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0017': {
        title: 'dh-111-i-0017',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0018': {
        title: 'dh-111-i-0018',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0019': {
        title: 'dh-111-i-0019',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0020': {
        title: 'dh-111-i-0020',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'account', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0021': {
        title: 'dh-111-i-0021',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'cart', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0022': {
        title: 'dh-111-i-0022',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_3',
                items: ['mainMenu', 'account', 'cart', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0023': {
        title: 'dh-111-i-0023',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_3',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                    'buttonPopup',
                ],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0024': {
        title: 'dh-111-i-0024',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_3',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                    'buttonPopup',
                ],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0025': {
        title: 'dh-111-i-0025',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_3',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                    'buttonPopup',
                ],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0026': {
        title: 'dh-111-i-0026',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_3',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                    'buttonPopup',
                ],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'account', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0027': {
        title: 'dh-111-i-0027',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_4',
                items: ['logo', 'search', 'phone', 'whatsappIconOnly', 'telegramIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0028': {
        title: 'dh-111-i-0028',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_4',
                items: ['logo', 'search', 'phone', 'whatsappIconOnly', 'telegramIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0029': {
        title: 'dh-111-i-0029',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_4',
                items: ['logo', 'search', 'phone', 'whatsappIconOnly', 'telegramIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0030': {
        title: 'dh-111-i-0030',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_4',
                items: ['logo', 'search', 'phone', 'whatsappIconOnly', 'telegramIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0031': {
        title: 'dh-111-i-0031',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_4',
                items: ['logo', 'search', 'phone', 'whatsappIconOnly', 'telegramIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'account', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0032': {
        title: 'dh-111-i-0032',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_4',
                items: ['logo', 'search', 'phone', 'whatsappIconOnly', 'telegramIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'cart', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0033': {
        title: 'dh-111-i-0033',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_4',
                items: ['logo', 'search', 'phone', 'whatsappIconOnly', 'telegramIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: '1_3',
                items: ['mainMenu', 'account', 'cart', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0034': {
        title: 'dh-111-i-0034',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: ['logo', 'search', 'buttonPhone', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0035': {
        title: 'dh-111-i-0035',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: ['logo', 'search', 'buttonPhone', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0036': {
        title: 'dh-111-i-0036',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: ['logo', 'search', 'buttonPhone', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0037': {
        title: 'dh-111-i-0037',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: ['logo', 'search', 'buttonPhone', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'account', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0038': {
        title: 'dh-111-i-0038',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '2_space_3',
                items: ['logo', 'buttonPopup', 'search', 'phone', 'account', 'cart'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0039': {
        title: 'dh-111-i-0039',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '2_space_3',
                items: ['logo', 'buttonPopup', 'search', 'phone', 'account', 'cart'],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0040': {
        title: 'dh-111-i-0040',
        template: [
            {
                type: 'Top',
                template: '1_2',
                items: ['infoMenu', 'cart', null],
            },
            {
                type: 'Main',
                template: '2_1_2',
                items: ['phones', 'whatsapps', 'logo', 'workTime', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0041': {
        title: 'dh-111-i-0041',
        template: [
            {
                type: 'Top',
                template: '1_2',
                items: ['infoMenu', 'cart', null],
            },
            {
                type: 'Main',
                template: '2_1_2',
                items: ['phones', 'whatsapps', 'logo', 'workTime', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0042': {
        title: 'dh-111-i-0042',
        template: [
            {
                type: 'Top',
                template: '1_2',
                items: ['infoMenu', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_1_3',
                items: ['search', 'logo', 'phone', 'whatsappIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0043': {
        title: 'dh-111-i-0043',
        template: [
            {
                type: 'Top',
                template: '1_2',
                items: ['infoMenu', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_1_3',
                items: ['search', 'logo', 'phone', 'whatsappIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0044': {
        title: 'dh-111-i-0044',
        template: [
            {
                type: 'Top',
                template: '1_2',
                items: ['infoMenu', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_1_2',
                items: [
                    'search',
                    'logo',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0045': {
        title: 'dh-111-i-0045',
        template: [
            {
                type: 'Top',
                template: '1_2',
                items: ['infoMenu', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_1_2',
                items: [
                    'search',
                    'logo',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0046': {
        title: 'dh-111-i-0046',
        template: [
            {
                type: 'Top',
                template: '1_2',
                items: ['infoMenu', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0047': {
        title: 'dh-111-i-0047',
        template: [
            {
                type: 'Top',
                template: '1_2',
                items: ['infoMenu', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0048': {
        title: 'dh-111-i-0048',
        template: [
            {
                type: 'Top',
                template: '1_2',
                items: ['infoMenu', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0049': {
        title: 'dh-111-i-0049',
        template: [
            {
                type: 'Top',
                template: '1_2',
                items: ['infoMenu', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_space_3',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                    'buttonPopup',
                ],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0050': {
        title: 'dh-111-i-0050',
        template: [
            {
                type: 'Top',
                template: '1_2',
                items: ['infoMenu', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_space_3',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                    'buttonPopup',
                ],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0051': {
        title: 'dh-111-i-0051',
        template: [
            {
                type: 'Top',
                template: '1_2',
                items: ['infoMenu', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_space_4',
                items: ['logo', 'search', 'phone', 'whatsappIconOnly', 'telegramIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0052': {
        title: 'dh-111-i-0052',
        template: [
            {
                type: 'Top',
                template: '1_2',
                items: ['infoMenu', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_space_4',
                items: ['logo', 'search', 'phone', 'whatsappIconOnly', 'telegramIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0053': {
        title: 'dh-111-i-0053',
        template: [
            {
                type: 'Top',
                template: '1_2',
                items: ['infoMenu', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_space_4',
                items: ['logo', 'search', 'phone', 'whatsappIconOnly', 'telegramIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0054': {
        title: 'dh-111-i-0054',
        template: [
            {
                type: 'Top',
                template: '1_2',
                items: ['infoMenu', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: ['logo', 'search', 'buttonPhone', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0055': {
        title: 'dh-111-i-0055',
        template: [
            {
                type: 'Top',
                template: '1_2',
                items: ['infoMenu', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: ['logo', 'search', 'buttonPhone', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0056': {
        title: 'dh-111-i-0056',
        template: [
            {
                type: 'Top',
                template: '1_2',
                items: ['infoMenu', 'cart', null],
            },
            {
                type: 'Main',
                template: '2_space_3',
                items: ['logo', 'buttonPopup', 'search', 'phone', 'account', 'cart'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0057': {
        title: 'dh-111-i-0057',
        template: [
            {
                type: 'Top',
                template: '1_2',
                items: ['infoMenu', 'cart', null],
            },
            {
                type: 'Main',
                template: '2_space_3',
                items: ['logo', 'buttonPopup', 'search', 'phone', 'account', 'cart'],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0058': {
        title: 'dh-111-i-0058',
        template: [
            {
                type: 'Top',
                template: '1_3',
                items: ['infoMenu', 'account', 'cart', null],
            },
            {
                type: 'Main',
                template: '2_1_2',
                items: ['phones', 'whatsapps', 'logo', 'workTime', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0059': {
        title: 'dh-111-i-0059',
        template: [
            {
                type: 'Top',
                template: '1_3',
                items: ['infoMenu', 'account', 'cart', null],
            },
            {
                type: 'Main',
                template: '2_1_2',
                items: ['phones', 'whatsapps', 'logo', 'workTime', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0060': {
        title: 'dh-111-i-0060',
        template: [
            {
                type: 'Top',
                template: '1_3',
                items: ['infoMenu', 'account', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_1_3',
                items: ['search', 'logo', 'phone', 'whatsappIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0061': {
        title: 'dh-111-i-0061',
        template: [
            {
                type: 'Top',
                template: '1_3',
                items: ['infoMenu', 'account', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_1_3',
                items: ['search', 'logo', 'phone', 'whatsappIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0062': {
        title: 'dh-111-i-0062',
        template: [
            {
                type: 'Top',
                template: '1_3',
                items: ['infoMenu', 'account', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_1_2',
                items: [
                    'search',
                    'logo',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0063': {
        title: 'dh-111-i-0063',
        template: [
            {
                type: 'Top',
                template: '1_3',
                items: ['infoMenu', 'account', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_1_2',
                items: [
                    'search',
                    'logo',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0064': {
        title: 'dh-111-i-0064',
        template: [
            {
                type: 'Top',
                template: '1_3',
                items: ['infoMenu', 'account', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0065': {
        title: 'dh-111-i-0065',
        template: [
            {
                type: 'Top',
                template: '1_3',
                items: ['infoMenu', 'account', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0066': {
        title: 'dh-111-i-0066',
        template: [
            {
                type: 'Top',
                template: '1_3',
                items: ['infoMenu', 'account', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_space_3',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                    'buttonPopup',
                ],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0067': {
        title: 'dh-111-i-0067',
        template: [
            {
                type: 'Top',
                template: '1_3',
                items: ['infoMenu', 'account', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_space_3',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                    'buttonPopup',
                ],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0068': {
        title: 'dh-111-i-0068',
        template: [
            {
                type: 'Top',
                template: '1_3',
                items: ['infoMenu', 'account', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_space_4',
                items: ['logo', 'search', 'phone', 'whatsappIconOnly', 'telegramIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0069': {
        title: 'dh-111-i-0069',
        template: [
            {
                type: 'Top',
                template: '1_3',
                items: ['infoMenu', 'account', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_space_4',
                items: ['logo', 'search', 'phone', 'whatsappIconOnly', 'telegramIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0070': {
        title: 'dh-111-i-0070',
        template: [
            {
                type: 'Top',
                template: '1_3',
                items: ['infoMenu', 'account', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: ['logo', 'search', 'buttonPhone', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0071': {
        title: 'dh-111-i-0071',
        template: [
            {
                type: 'Top',
                template: '1_3',
                items: ['infoMenu', 'account', 'cart', null],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: ['logo', 'search', 'buttonPhone', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0072': {
        title: 'dh-111-i-0072',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0073': {
        title: 'dh-111-i-0073',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0074': {
        title: 'dh-111-i-0074',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0075': {
        title: 'dh-111-i-0075',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0076': {
        title: 'dh-111-i-0076',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'account', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0077': {
        title: 'dh-111-i-0077',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'cart', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0078': {
        title: 'dh-111-i-0078',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_3',
                items: ['mainMenu', 'account', 'cart', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0079': {
        title: 'dh-111-i-0079',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_3',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                    'buttonPopup',
                ],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0080': {
        title: 'dh-111-i-0080',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_3',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                    'buttonPopup',
                ],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0081': {
        title: 'dh-111-i-0081',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_3',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                    'buttonPopup',
                ],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0082': {
        title: 'dh-111-i-0082',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_3',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                    'buttonPopup',
                ],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'account', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0083': {
        title: 'dh-111-i-0083',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: ['logo', 'search', 'buttonPhone', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0084': {
        title: 'dh-111-i-0084',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: ['logo', 'search', 'buttonPhone', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0085': {
        title: 'dh-111-i-0085',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: ['logo', 'search', 'buttonPhone', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0086': {
        title: 'dh-111-i-0086',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: ['logo', 'search', 'buttonPhone', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'account', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0087': {
        title: 'dh-111-i-0087',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '2_space_3',
                items: ['logo', 'buttonPopup', 'search', 'phone', 'account', 'cart'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0088': {
        title: 'dh-111-i-0088',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '2_space_3',
                items: ['logo', 'buttonPopup', 'search', 'phone', 'account', 'cart'],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0089': {
        title: 'dh-111-i-0089',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '2_1_2',
                items: ['phones', 'whatsapps', 'logo', 'workTime', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0090': {
        title: 'dh-111-i-0090',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '2_1_2',
                items: ['phones', 'whatsapps', 'logo', 'workTime', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0091': {
        title: 'dh-111-i-0091',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_1_3',
                items: ['search', 'logo', 'phone', 'whatsappIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0092': {
        title: 'dh-111-i-0092',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_1_3',
                items: ['search', 'logo', 'phone', 'whatsappIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0093': {
        title: 'dh-111-i-0093',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_1_2',
                items: [
                    'search',
                    'logo',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0094': {
        title: 'dh-111-i-0094',
        template: [
            {
                type: 'Top',
                template: '1_1',
                items: ['infoMenu', 'socials'],
            },
            {
                type: 'Main',
                template: '1_1_2',
                items: [
                    'search',
                    'logo',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0095': {
        title: 'dh-111-i-0095',
        template: [
            {
                type: 'Top',
                template: '1_1_1',
                items: ['infoMenu', 'customText', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0096': {
        title: 'dh-111-i-0096',
        template: [
            {
                type: 'Top',
                template: '1_1_1',
                items: ['infoMenu', 'customText', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0097': {
        title: 'dh-111-i-0097',
        template: [
            {
                type: 'Top',
                template: '1_1_1',
                items: ['infoMenu', 'customText', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0098': {
        title: 'dh-111-i-0098',
        template: [
            {
                type: 'Top',
                template: '1_1_1',
                items: ['infoMenu', 'customText', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0099': {
        title: 'dh-111-i-0099',
        template: [
            {
                type: 'Top',
                template: '1_1_1',
                items: ['infoMenu', 'customText', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'account', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0100': {
        title: 'dh-111-i-0100',
        template: [
            {
                type: 'Top',
                template: '1_1_1',
                items: ['infoMenu', 'customText', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'cart', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0101': {
        title: 'dh-111-i-0101',
        template: [
            {
                type: 'Top',
                template: '1_1_1',
                items: ['infoMenu', 'customText', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1_3',
                items: ['mainMenu', 'account', 'cart', 'buttonPopup'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0102': {
        title: 'dh-111-i-0102',
        template: [
            {
                type: 'Top',
                template: '1_1_1',
                items: ['infoMenu', 'customText', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_3',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                    'buttonPopup',
                ],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0103': {
        title: 'dh-111-i-0103',
        template: [
            {
                type: 'Top',
                template: '1_1_1',
                items: ['infoMenu', 'customText', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_3',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                    'buttonPopup',
                ],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0104': {
        title: 'dh-111-i-0104',
        template: [
            {
                type: 'Top',
                template: '1_1_1',
                items: ['infoMenu', 'customText', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_3',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                    'buttonPopup',
                ],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0105': {
        title: 'dh-111-i-0105',
        template: [
            {
                type: 'Top',
                template: '1_1_1',
                items: ['infoMenu', 'customText', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_3',
                items: [
                    'logo',
                    'search',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                    'buttonPopup',
                ],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'account', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0106': {
        title: 'dh-111-i-0106',
        template: [
            {
                type: 'Top',
                template: '1_1_1',
                items: ['infoMenu', 'customText', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: ['logo', 'search', 'buttonPhone', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0107': {
        title: 'dh-111-i-0107',
        template: [
            {
                type: 'Top',
                template: '1_1_1',
                items: ['infoMenu', 'customText', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: ['logo', 'search', 'buttonPhone', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0108': {
        title: 'dh-111-i-0108',
        template: [
            {
                type: 'Top',
                template: '1_1_1',
                items: ['infoMenu', 'customText', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: ['logo', 'search', 'buttonPhone', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: '1_1',
                items: ['mainMenu', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0109': {
        title: 'dh-111-i-0109',
        template: [
            {
                type: 'Top',
                template: '1_1_1',
                items: ['infoMenu', 'customText', 'socials'],
            },
            {
                type: 'Main',
                template: '1_space_2',
                items: ['logo', 'search', 'buttonPhone', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: '1_2',
                items: ['mainMenu', 'account', 'cart'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0110': {
        title: 'dh-111-i-0110',
        template: [
            {
                type: 'Top',
                template: '1_1_1',
                items: ['infoMenu', 'customText', 'socials'],
            },
            {
                type: 'Main',
                template: '2_space_3',
                items: ['logo', 'buttonPopup', 'search', 'phone', 'account', 'cart'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0111': {
        title: 'dh-111-i-0111',
        template: [
            {
                type: 'Top',
                template: '1_1_1',
                items: ['infoMenu', 'customText', 'socials'],
            },
            {
                type: 'Main',
                template: '2_space_3',
                items: ['logo', 'buttonPopup', 'search', 'phone', 'account', 'cart'],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0112': {
        title: 'dh-111-i-0112',
        template: [
            {
                type: 'Top',
                template: '1_1_1',
                items: ['infoMenu', 'customText', 'socials'],
            },
            {
                type: 'Main',
                template: '2_1_2',
                items: ['phones', 'whatsapps', 'logo', 'workTime', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0113': {
        title: 'dh-111-i-0113',
        template: [
            {
                type: 'Top',
                template: '1_1_1',
                items: ['infoMenu', 'customText', 'socials'],
            },
            {
                type: 'Main',
                template: '2_1_2',
                items: ['phones', 'whatsapps', 'logo', 'workTime', 'buttonPopup'],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0114': {
        title: 'dh-111-i-0114',
        template: [
            {
                type: 'Top',
                template: '1_1_1',
                items: ['infoMenu', 'customText', 'socials'],
            },
            {
                type: 'Main',
                template: '1_1_3',
                items: ['search', 'logo', 'phone', 'whatsappIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0115': {
        title: 'dh-111-i-0115',
        template: [
            {
                type: 'Top',
                template: '1_1_1',
                items: ['infoMenu', 'customText', 'socials'],
            },
            {
                type: 'Main',
                template: '1_1_3',
                items: ['search', 'logo', 'phone', 'whatsappIconOnly', 'emailIconOnly'],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0116': {
        title: 'dh-111-i-0116',
        template: [
            {
                type: 'Top',
                template: '1_1_1',
                items: ['infoMenu', 'customText', 'socials'],
            },
            {
                type: 'Main',
                template: '1_1_2',
                items: [
                    'search',
                    'logo',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: 'center',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
    'dh-111-i-0117': {
        title: 'dh-111-i-0117',
        template: [
            {
                type: 'Top',
                template: '1_1_1',
                items: ['infoMenu', 'customText', 'socials'],
            },
            {
                type: 'Main',
                template: '1_1_2',
                items: [
                    'search',
                    'logo',
                    {
                        component: 'column',
                        props: {
                            items: ['phone', 'whatsapp'],
                        },
                    },
                    {
                        component: 'column',
                        props: {
                            items: ['telegram', 'email'],
                        },
                    },
                ],
            },
            {
                type: 'Bottom',
                template: '1',
                items: ['mainMenu'],
            },
        ],
        locate: 'inline',
        onScroll: 'static',
    },
} as { [key: string]: any };
