import Header from '@/entities/section/fixtures/headerDesktop/Header';
import fixtures from '@/entities/section/fixtures/headerDesktop/fixtures';

const items = {} as { [key: string]: any };
for (const key in fixtures) {
    items[key] = new Header(fixtures[key]);
}

const itemsForSelect = Object.keys(items).map((key) => {
    return { title: key, preview: items[key].preview, tags: items[key].tags };
});

export { items, itemsForSelect };
